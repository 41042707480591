.site-tabs {
    .ant-tabs-nav-wrap{
        min-height: calc(100vh - 370px);
    }
    .ant-tabs-extra-content {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
