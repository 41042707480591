.mobile {
    .layout-page {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: var(--adm-color-background);

        .layout-page-top {
            flex: 0;
            border-bottom: solid 1px var(--adm-color-border);
        }

        .layout-page-body {
            flex: 1;
            margin: 16px;
        }

        .layout-page-bottom {
            flex: 0;
            border-top: solid 1px var(--adm-color-border)
        }
    }
}
