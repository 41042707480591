.tablet{
    .layout-page{
        min-height: 100vh;
        .logo {
            height: 32px;
            margin: 8px;
            background: rgba(255,255,255,.2);
            border-radius: 6px;
        }
        .route-view{
            
            min-height: calc(100vh - 200px);
        }
    }   
}
