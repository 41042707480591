.page-login {
    width: 100%;
    height: 100vh;
    background: #0088EE;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-wrap {
        position: relative;
        display: flex;
        width: 1000px;
        height: 500px;
        background: rgba(255, 255, 255, 1);
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);

        .login-img {
            width: 500px;
            height: 500px;
            padding: 50px;
        }

        .login-form {
            width: 500px;
            height: 500px;
            padding: 50px;
        }

        .login-title {
            text-align: center;
            font-size: 26px;
            color: #008EF9;
        }
    }

    .copyright {
        font-size: 12px;
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        text-align: center;
        padding: 20px 0;
        color: rgba(255, 255, 255, .8);
    }

    .balls {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 100vh;

        .ball {
            position: relative;
            border-radius: 100%;
            background-image: linear-gradient(180deg, #54B4FE 2%, #0039F2 100%);
            box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
            opacity: 0.2;
        }

        .ball::before,
        .ball::after {
            position: absolute;
            content: '';
            border-radius: 100%;
            background-image: linear-gradient(180deg, #54B4FE 2%, #0039F2 100%);
        }

        .ball1 {
            width: 100px;
            height: 100px;
            transform: translate(-500px, 50px);
        }

        .ball2 {
            width: 200px;
            height: 200px;
            transform: translate(600px, -50%);
        }

        .ball3 {
            width: 250px;
            height: 250px;
            transform: translate(-1200px, 50%);
        }

        .ball::before {
            left: 100%;
            top: -200%;
            width: 50%;
            height: 50%;
        }

        .ball::after {
            left: 200%;
            top: 200%;
            width: 50%;
            height: 50%;

        }
    }
}
