// 主要内容区 card内的表格
.page-row-full,
.page-card-full {
    min-height: calc(100vh - 165px);

    >.ant-card-body {
        height: calc(100vh - 165px - 56px);
    }

    .ant-spin-nested-loading {
        height: calc(100vh - 165px - 56px - 56px);
    }

    .table-full {
        .ant-table-body {
            overflow-y: auto !important;
        }

        .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ant-table-empty {
                flex-grow: 2;

                .ant-table-cell {
                    border-color: transparent;

                    .ant-empty {
                        margin-top: 30vh;
                    }
                }
            }
        }
    }
}


// 配置下拉选择图标选择框列表
.custom {

    .rc-virtual-list-holder-inner {
        flex-direction: row !important;
        flex-wrap: wrap;

        .ant-select-item {
            width: 100%;
        }

        .ant-select-item.ant-select-item-option-grouped {
            width: 10%;
            padding: 5px;
            justify-content: center;
            align-items: center;
            text-align: center;

            .ant-select-item-option-content {
                min-width: 10% !important;

                .anticon {
                    font-size: 22px !important;
                    color: #434343;
                }
            }
        }
    }
}


.drawer-tabs {
    .ant-tabs-content.ant-tabs-content-left {
        height: calc(100vh - 114px) !important;
        overflow-y: auto;
    }
}

.table-drawer {
    .ant-table-body {
        overflow-y: auto !important;
    }

    .ant-spin-container {
        height: calc(100vh - 114px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .ant-table-empty {
            flex-grow: 2;

            .ant-table-cell {
                border-color: transparent;

                .ant-empty {
                    margin-top: 30vh;
                }
            }
        }
    }
}
// 隐藏摄像头顶部
#camera_play-headControl{
    display: none !important;
}
