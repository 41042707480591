.browser {
    .layout-page {
        min-height: 100vh;

        .logo {
            height: 48px;
            margin: 8px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                transition: all 1s;
            }
        }

        .route-view {
            min-height: calc(100vh - 165px);
        }
    }
}

.config {
    .cell {
        margin-bottom: 32px;

        .cell-label {
            margin-bottom: 16px;
        }

        .cell-content {
            display: flex;
            align-items: center;

            .algorithm {
                width: 150px;
                height: 80px;
                border-radius: 4px;
                position: relative;
                box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, .18);
                cursor: pointer;

                &:nth-child(odd) {
                    margin-right: 15px;
                }

                &:nth-child(even) {
                    margin-left: 15px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 25px;
                    height: 80px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 150px;
                    height: 15px;
                    left: 0;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                &.light {
                    background-color: #f5f5f5;

                    &::before {
                        background-color: white;
                        z-index: 99;
                    }

                    &::after {
                        background-color: white;
                    }
                }

                &.dark {
                    background-color: #000;

                    &::before {
                        background-color: #141414;
                        z-index: 99;
                    }

                    &::after {
                        background-color: #141414;
                    }
                }

                .ant-checkbox-wrapper {
                    position: absolute;
                    z-index: 99;
                    right: 5px;
                    bottom: 0px;
                }
            }

            .theme-color {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                position: relative;
                box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, .18);
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
