.map-card {
    &.hideHeader{
        .ant-card-head{
            display: none;
        }
    }
    .required {
        .ant-card-head {
            position: relative;

            &::before {
                display: inline-block;
                margin-inline-end: 4px;
                color: #dc4446;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: "*";
                position: absolute;
                width: 7px;
                left: 5px;
                top: 20px;
            }
        }
    }

    .ant-drawer-content-wrapper {
        top: 55px;
    }

    .map-container {
        width: 100%;
        height: 100%;
        outline: none;
        background-color: #333;
        position: relative;
        border-bottom-right-radius: var(--adm-radius-m);
        border-bottom-left-radius: var(--adm-radius-m);
        transition: width 0.4s ease-in-out;

        &.comparison {
            width: 50%;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 500;

        }

        // 标尺
        &::before {
            width: 15px;
            height: calc(100% - 9px);
            left: 4px;
            bottom: 4px;
            border-left: 2px solid var(--colorBorderBg);
            background: repeating-linear-gradient(180deg, transparent, transparent var(--scale), var(--colorBorderBg), var(--colorBorderBg) calc(var(--scale) + 2px));
        }

        // 标尺
        &::after {
            width: calc(100% - 9px);
            height: 15px;
            bottom: 4px;
            left: 4px;
            border-bottom: 2px solid var(--colorBorderBg);
            background: repeating-linear-gradient(90deg, transparent, transparent var(--scale), var(--colorBorderBg), var(--colorBorderBg) calc(var(--scale) + 2px));
        }

        // 版权样式
        .leaflet-control-attribution.leaflet-control {
            margin: 6px;

            a,
            span {
                display: none !important;
            }
        }

        // 标尺
        .leaflet-control-scale.leaflet-control {
            .leaflet-control-scale-line {
                &:nth-child(1) {
                    border: none;
                    background-color: rgba(255, 255, 255, 0.5);
                }

                &:nth-child(2) {
                    display: none;
                }
            }
        }

        path {
            outline: none;
        }

        .leaflet-pane.leaflet-overlay-pane {

            .measurements-text {
                font-size: 15px;
                color: black;
                text-shadow: -1px 0 0 white, -1px -1px 0 white, 0 -1px 0 white, 1px -1px 0 white, 1px 0 0 white, 1px 1px 0 white, 0 1px 0 white, -1px 1px 0 white;
                transform-origin: center;
            }
        }
    }

    .zoomControl {
        position: absolute;
        left: 32px;
        bottom: 32px;
        z-index: 500;
    }


}
